import { createSlice } from "@reduxjs/toolkit";

const chat = createSlice({
    name:"chat",
    initialState:{
        latestMessage:null,
        chatStaffList:[],
        chatDetails:null,
        activeChat:null,
        chatGroup:null
    },
    reducers:{
       addLatestMessage:(state, action)=>{
            state.latestMessage=action.payload
            if (action.payload?.chat_group_id===state.chatGroup?.id) {
                state.chatDetails?.push(action.payload)
            }
       },
       addStaffList:(state, action)=>{
        state.chatStaffList=action.payload
       },
       addChatStaffDetails:(state, action)=>{
        state.chatDetails=action.payload
       },
       addActiveChat:(state, action)=>{
        state.activeChat=action.payload
       },
       addOnlineUser:(state, action)=>{
            const newStaff = state.chatStaffList?.map(staff => {
                const isOnline = action.payload.some(id => id === staff.user_id);
                return {
                    ...staff,
                    online: isOnline,
                };
            });
            state.chatStaffList = newStaff;
       },
       addChatGroup:(state, action)=>{
            state.chatGroup=action.payload
       }
    }
})

export const {addLatestMessage, addChatStaffDetails, 
    addStaffList, addActiveChat, addOnlineUser, addChatGroup}=chat.actions

export default chat.reducer

// get chat details
export const getLatestMessage=(state)=>state.chat.latestMessage
export const getChatStaffList=(state)=>state.chat.chatStaffList
export const getChatDetails=(state)=>state.chat.chatDetails
export const getActiveChat=(state)=>state.chat.activeChat
export const getChatGroup=(state)=>state.chat.chatGroup

