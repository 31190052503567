import { createSlice } from "@reduxjs/toolkit";

// initial form
export const activityForm={
    activity_code:"",
    title:"",
    points:0,
    duration:0,
    type:"Program Activity",
    date:"",
    area_of_development:"",
    primary_goal:"",
    atomic_goal:"",
    stage:"",
    primary_skill:"",
    secondary_skill:"",
    term_id:"",
    topic_id:""
}

const activitiesSlice = createSlice({
    name:"activitiesSlice",
    initialState:{
        popup:{
            activityPopup:false,
            editActivityPopup:false,
            completedActivityPopup:false,
            deleteActivityPopup:false
        },
        form:{
            activityForm:activityForm,
            editActivityForm:activityForm
        },
        other:{
            activityInformation:null
        }
    },
    reducers:{
        // popup reducer action
        openActivityPopup:(state)=>{
            state.popup.activityPopup=true
        },
        closeActivityPopup:(state)=>{
            state.popup.activityPopup=false
        },
        openEditActivityPopup:(state, action)=>{
            state.popup.editActivityPopup=true
            state.form.editActivityForm=action.payload
        },
        closeEditActivityPopup:(state)=>{
            state.popup.editActivityPopup=false
            state.form.editActivityForm=activityForm
        },
        openCompleteActivityPopup:(state, action)=>{
            state.popup.completedActivityPopup=true
            state.other.activityInformation=action.payload
        },
        closeCompleteActivityPopup:(state)=>{
            state.popup.completedActivityPopup=false
            state.other.activityInformation=null
        },
        closeDeleteActivityPopup:(state)=>{
            state.popup.deleteActivityPopup=false
        },
        openDeleteActivityPopup:(state)=>{
            state.popup.deleteActivityPopup=true
        },

        // form reducer
        addActivityForm:(state, action)=>{
            state.form.activityForm=action.payload
        },
        editActivityForm:(state, action)=>{
            state.form.editActivityForm=action.payload
        }
    }
})

export default activitiesSlice.reducer

export const {openActivityPopup, closeActivityPopup, addActivityForm,
    editActivityForm, openEditActivityPopup, closeEditActivityPopup,
    closeCompleteActivityPopup, openCompleteActivityPopup, openDeleteActivityPopup,
    closeDeleteActivityPopup
}=activitiesSlice.actions

// get popup state
export const getActivityPopup = (state) => state.activities.popup.activityPopup
export const getEditActivityPopup = (state) => state.activities.popup.editActivityPopup
export const getCompletedActivityPopup = (state) => state.activities.popup.completedActivityPopup
export const getDeleteActivityPopup = (state) => state.activities.popup.deleteActivityPopup

// get form state
export const getActivityForm = (state) => state.activities.form.activityForm
export const getEditActivityForm = (state) => state.activities.form.editActivityForm

// get other state
export const getActivityInformation = (state) => state.activities.other.activityInformation
