// mues tech server
// export const mainUrl="http://192.168.1.47:8002/" // local server
export const mainUrl="https://dev.backend.mues.tech/" // development server
// export const mainUrl="https://test.backend.mues.tech/" // testing server
// export const mainUrl="http://3.110.119.136:8000/" // production server

// mues aus server
// export const mainUrl="http://13.54.68.36:8000/" // development server

// curriculum server
export const curriculumUrl="http://15.206.151.172:8000/" // development server
// export const curriculumUrl="http://192.168.1.47:8006/" // local server
// export const curriculumUrl="http://13.234.78.119:8000/" // testing server
// export const curriculumUrl="http://13.234.202.119:8000/" // production server

// curriculum aus server
// export const curriculumUrl="http://35.154.250.38:8000/" // development server


// financial server
// export const financialUrl="http://65.0.199.136:8000/" // development server
export const financialUrl="http://192.168.1.47:5000/" // local server
// export const financialUrl="http://65.1.133.94:8000/" // testing server
// export const financialUrl="http://43.205.118.39:8000/" // production server

// chat server 
export const chatServer="wss://dev.messaging.mues.tech/ws/chat/" // development server
// export const chatServer="ws://192.168.1.47:8003/ws/chat/" // local server
// export const chatServer="http://3.109.4.17:8000/principal-chat/" // testing server
// export const chatServer="http://65.1.64.18:8000/principal-chat/" // production server

// duplicate backend server
// export const mainUrl="http://13.234.204.16:8000/" // local server

